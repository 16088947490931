import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import Banner from 'containers/AppMinimal/Banner';
import KeyFeatures from 'containers/AppMinimal/KeyFeatures';
import SecureTransaction from "containers/AppMinimal/SecureTransaction";
import ChooseUs from 'containers/AppMinimal/ChooseUs';
import Counter from 'containers/AppMinimal/Counter';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import {getQueryParam} from "../custom/getqueryparam";
import {useLocation} from "@reach/router";
import {useCookies} from "react-cookie";
import CounterV0 from "../containers/AppMinimal/CounterV0";
import FlightForm from "../containers/AppMinimal/FlightForm";
import Footer from "../containers/AppMinimal/Footer";
import TestimonialSection from "../containers/SaasModern/Testimonial";
import Container from "../common/components/UI/Container";


const AppMinimal = () => {

    const reference = getQueryParam("gclid", useLocation())

    const [cookie, setCookie] = useCookies([])


    useEffect(() => {
        if (reference !== "") {
            setCookie("customer.gclid", reference, {path: "/"})
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Home"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <FlightForm/>
                        <Container>
                            <div style={{marginTop: "30px"}}>
                            <img src="/img/logos.png" width="100%"/>
                            <img src="/img/line.png" width="100%" style={{marginTop: "40px"}}/>
                            </div>
                        </Container>
                        <TestimonialSection/>
                        <KeyFeatures/>

                        <div className="transition">
                        </div>
                        <Counter/>

                        <ChooseUs/>
                        <CounterV0/>
                        <SecureTransaction/>
                        <Footer/>
                    </ContentWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default AppMinimal;
